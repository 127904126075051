import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ProgressChart = ({ data }) => {
  const chartData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'], // Aquí puedes hacer dinámico dependiendo de cómo se vayan a mostrar las fechas
    datasets: [
      {
        label: 'Capital Invertido',
        data: data.capitalInvested,
        fill: false,
        borderColor: 'rgba(0, 255, 0, 1)',
        tension: 0.1,
      },
      {
        label: 'Bonos por Referidos',
        data: data.referralBonuses,
        fill: false,
        borderColor: 'rgba(255, 165, 0, 1)',
        tension: 0.1,
      },
      {
        label: 'Ganancias por Inversión',
        data: data.investmentEarnings,
        fill: false,
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#FFD700',
        },
      },
      title: {
        display: true,
        text: 'Progreso Financiero',
        color: '#FFD700',
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#FFD700',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      y: {
        ticks: {
          color: '#FFD700',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default ProgressChart;
