import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const ProcessPay = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col justify-center items-center">
      <div className="bg-gray-800 p-10 rounded-lg shadow-lg max-w-xl text-center">
        <h2 className="text-3xl font-bold text-green-600">Procesando tu compra...</h2>
        <p className="text-gray-400 mt-4">
          Tu pago está en proceso de verificación. Este proceso puede tardar hasta 2 horas. 
          Te notificaremos cuando el saldo esté disponible en tu cuenta.
        </p>
        <div className="flex justify-center items-center mt-6">
          <FaSpinner className="text-4xl text-green-600 animate-spin" />
        </div>
        <p className="text-gray-500 mt-4">Por favor, espera mientras procesamos tu solicitud.</p>
      </div>
    </div>
  );
};

export default ProcessPay;
