import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify'; // Importar Toastify
import 'react-toastify/dist/ReactToastify.css';

const AdminPlans = () => {
  const [pendingPlans, setPendingPlans] = useState([]);

  useEffect(() => {
    fetchPendingPlans();
  }, []);

  const fetchPendingPlans = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/plans/pending`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener las compras pendientes');
      }

      const data = await response.json();
      setPendingPlans(data); // Asegurarse de actualizar el estado correctamente
    } catch (error) {
      console.error('Error fetching pending plans:', error);
      toast.error('Error al obtener las compras pendientes');
    }
  };

  const handleApprove = async (planId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/plans/confirm`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ purchaseId: planId }),
      });
      if (response.ok) {
        setPendingPlans(pendingPlans.filter(plan => plan.id !== planId));
        toast.success('Plan aprobado y activado con éxito');
      } else {
        const data = await response.json();
        toast.error(`Error al aprobar el plan: ${data.message}`);
      }
    } catch (error) {
      console.error('Error approving plan:', error);
      toast.error('Error al aprobar el plan');
    }
  };

  const handleReject = async (planId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/plans/reject`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ purchaseId: planId }),
      });
      if (response.ok) {
        setPendingPlans(pendingPlans.filter(plan => plan.id !== planId));
        toast.info('Plan rechazado exitosamente');
      } else {
        const data = await response.json();
        toast.error(`Error al rechazar el plan: ${data.message}`);
      }
    } catch (error) {
      console.error('Error rejecting plan:', error);
      toast.error('Error al rechazar el plan');
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <h2 className="text-2xl font-bold mb-4">Gestión de Planes Pendientes</h2>
      <table className="min-w-full bg-gray-800 rounded-lg">
        <thead>
          <tr>
            <th className="text-left px-6 py-3">Usuario</th>
            <th className="text-left px-6 py-3">Plan</th>
            <th className="text-left px-6 py-3">Cantidad</th>
            <th className="text-left px-6 py-3">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {pendingPlans.length > 0 ? (
            pendingPlans.map(plan => (
              <tr key={plan.id}>
                <td className="px-6 py-3">{plan.username}</td>
                <td className="px-6 py-3">{plan.planname}</td>
                <td className="px-6 py-3">${plan.amount}</td>
                <td className="px-6 py-3">
                  <button
                    className="bg-green-600 px-4 py-2 rounded-md hover:bg-green-500 mr-2"
                    onClick={() => handleApprove(plan.id)}
                  >
                    Aprobar
                  </button>
                  <button
                    className="bg-red-600 px-4 py-2 rounded-md hover:bg-red-500"
                    onClick={() => handleReject(plan.id)}
                  >
                    Rechazar
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center py-4 text-gray-400">No hay planes pendientes.</td>
            </tr>
          )}
        </tbody>
      </table>

      <ToastContainer /> {/* Añadir el componente ToastContainer */}
    </div>
  );
};

export default AdminPlans;
