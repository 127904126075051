import React, { createContext, useContext, useState } from 'react';
import texts from '../Texts';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const getText = (key) => {
    return texts[language][key] || key;
  };

  return (
    <LanguageContext.Provider value={{ getText, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
