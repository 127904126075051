import React, { useState, useEffect } from 'react';
import { FaWallet } from 'react-icons/fa';

const Comissions = () => {
  const [commissions, setCommissions] = useState([]);

  useEffect(() => {
    fetchCommissionsData();
  }, []);

  const fetchCommissionsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/comissions`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setCommissions(data);
    } catch (error) {
      console.error('Error al obtener las comisiones:', error);
    }
  };

  const handlePay = async (bonusId) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/comissions/pay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ bonusId }),
      });
      // Actualizar la lista de comisiones
      fetchCommissionsData();
    } catch (error) {
      console.error('Error al pagar la comisión:', error);
    }
  };

  const handleReject = async (bonusId) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/comissions/reject`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ bonusId }),
      });
      // Actualizar la lista de comisiones
      fetchCommissionsData();
    } catch (error) {
      console.error('Error al rechazar la comisión:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <h2 className="text-2xl font-bold mb-4">Comisiones por Referidos</h2>
      {commissions.length > 0 ? (
        <table className="min-w-full bg-gray-800 rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-200">Usuario</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-200">Monto Comisión</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-200">Fecha Generada</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-200">Wallet</th>
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-200">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {commissions.map((commission, index) => (
              <tr key={index} className="hover:bg-gray-700">
                <td className="px-4 py-2 border-b border-gray-700 text-sm text-gray-200">{commission.username}</td>
                <td className="px-4 py-2 border-b border-gray-700 text-sm text-gray-200">${commission.amount}</td>
                <td className="px-4 py-2 border-b border-gray-700 text-sm text-gray-200">
                  {new Date(commission.date).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border-b border-gray-700 text-sm text-gray-200">
                  <FaWallet className="inline-block mr-2" />
                  {commission.wallet ? commission.wallet : 'No Wallet'}
                </td>
                <td className="px-4 py-2 border-b border-gray-700 text-sm text-gray-200">
                  <button
                    className="px-4 py-2 bg-green-600 text-white rounded-md mr-2"
                    onClick={() => handlePay(commission.id)}
                  >
                    Pagar
                  </button>
                  <button
                    className="px-4 py-2 bg-red-600 text-white rounded-md"
                    onClick={() => handleReject(commission.id)}
                  >
                    Rechazar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-400">No hay comisiones disponibles para mostrar.</p>
      )}
    </div>
  );
};

export default Comissions;
