import React, { useEffect, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar';

const AdminWithdrawals = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPendingWithdrawals();
  }, []);

  const fetchPendingWithdrawals = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pending-withdrawals`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setWithdrawals(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pending withdrawals:', error);
      toast.error('Error fetching pending withdrawals');
      setLoading(false);
    }
  };

  const handleApprove = async (withdrawalId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/approve-withdrawal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ withdrawalId }),
      });

      if (response.ok) {
        toast.success('Withdrawal approved successfully');
        fetchPendingWithdrawals(); // Refrescar la lista de retiros
      } else {
        toast.error('Error approving withdrawal');
      }
    } catch (error) {
      console.error('Error approving withdrawal:', error);
      toast.error('Error approving withdrawal');
    }
  };

  const handleReject = async (withdrawalId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/reject-withdrawal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ withdrawalId }),
      });

      if (response.ok) {
        toast.success('Withdrawal rejected successfully');
        fetchPendingWithdrawals(); // Refrescar la lista de retiros
      } else {
        toast.error('Error rejecting withdrawal');
      }
    } catch (error) {
      console.error('Error rejecting withdrawal:', error);
      toast.error('Error rejecting withdrawal');
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <NavigationBar userName="Admin" />  {/* Barra de navegación del Admin */}

      <div className="p-6 space-y-8">
        <h2 className="text-2xl font-bold text-teal-400 mb-4">Retiros Pendientes</h2>

        {loading ? (
          <p className="text-white">Cargando retiros...</p>
        ) : withdrawals.length === 0 ? (
          <p className="text-white">No hay retiros pendientes.</p>
        ) : (
          <table className="min-w-full text-left border-collapse">
            <thead>
              <tr>
                <th className="border-b border-gray-700 p-4">Usuario</th>
                <th className="border-b border-gray-700 p-4">Monto Solicitado</th>
                <th className="border-b border-gray-700 p-4">Monto Final</th>
                <th className="border-b border-gray-700 p-4">Comisión (%)</th>
                <th className="border-b border-gray-700 p-4">Wallet</th>
                <th className="border-b border-gray-700 p-4">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {withdrawals.map((withdrawal) => (
                <tr key={withdrawal.id} className="hover:bg-gray-700">
                  <td className="border-b border-gray-700 p-4">{withdrawal.user_id}</td>
                  <td className="border-b border-gray-700 p-4">${withdrawal.amount.toLocaleString()}</td>
                  <td className="border-b border-gray-700 p-4">${withdrawal.final_amount.toLocaleString()}</td>  {/* Mostrar monto final */}
                  <td className="border-b border-gray-700 p-4">{withdrawal.commission_percentage}%</td>  {/* Mostrar porcentaje de comisión */}
                  <td className="border-b border-gray-700 p-4">{withdrawal.wallet_address}</td>
                  <td className="border-b border-gray-700 p-4">
                    <button
                      className="mr-2 bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-500"
                      onClick={() => handleApprove(withdrawal.id)}
                    >
                      <FaCheck />
                    </button>
                    <button
                      className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-500"
                      onClick={() => handleReject(withdrawal.id)}
                    >
                      <FaTimes />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default AdminWithdrawals;
