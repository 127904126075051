import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TermsAndConditions = () => {

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="min-h-screen bg-black text-white p-10">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-5xl font-bold text-center mb-8" data-aos="fade-up">Términos y Condiciones</h1>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">1. Introducción</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Bienvenido a Findex. Al utilizar los servicios de nuestra plataforma, usted acepta cumplir con los presentes Términos y Condiciones.
            Estos términos regulan el uso de nuestros productos y servicios, incluyendo los programas de ahorro capitalizable y los proyectos de
            crowdfunding, así como cualquier otra oferta de servicios disponible a través de Findex.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">2. Servicios de Ahorro Capitalizable</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Findex ofrece productos de ahorro capitalizable con la posibilidad de generar rendimientos. Sin embargo, es importante señalar que todas
            las inversiones conllevan un nivel de riesgo. Aunque nuestros modelos buscan mitigar estos riesgos, los resultados pasados no son indicativos
            de rendimientos futuros, y no se garantiza la obtención de beneficios.
          </p>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Findex no se hace responsable por pérdidas derivadas de las inversiones realizadas a través de nuestros programas de ahorro. Toda decisión de
            inversión es bajo su propio riesgo, y recomendamos realizar una evaluación detallada de su situación financiera antes de participar en nuestros
            productos de inversión.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">3. Servicios de Crowdfunding</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Findex también facilita la creación e inversión en proyectos de crowdfunding previamente analizados. Si bien estos proyectos son evaluados
            rigurosamente para reducir riesgos, no se puede garantizar el éxito o la rentabilidad de los mismos. La inversión en proyectos de crowdfunding
            también implica asumir riesgos, y Findex no será responsable de pérdidas asociadas a dichos proyectos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">4. Responsabilidad del Inversor</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            El usuario reconoce que toda inversión, ya sea en nuestros productos de ahorro capitalizable o en proyectos de crowdfunding, es una decisión
            personal que debe ser tomada con precaución. Findex no garantiza rendimientos y no será responsable de pérdidas financieras. El inversor es
            plenamente responsable de cualquier decisión de inversión y sus consecuencias.
          </p>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Se recomienda encarecidamente que, antes de tomar decisiones importantes de inversión, los usuarios accedan a nuestros cursos y capacitaciones
            en Findex Edge, donde podrán adquirir conocimientos en educación financiera, desarrollo empresarial, liderazgo, blockchain y más.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">5. Limitación de Responsabilidad</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Findex no será responsable por pérdidas económicas directas, indirectas, incidentales, especiales o consecuentes, que surjan del uso de nuestros
            servicios o de la incapacidad de utilizar los mismos. Esto incluye, entre otros, pérdidas derivadas de decisiones de inversión, ya sea en nuestros
            productos de ahorro o en proyectos de crowdfunding.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">6. Política de No Garantía</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Findex no garantiza ni asegura la rentabilidad de las inversiones ofrecidas en nuestra plataforma. Los usuarios aceptan que los rendimientos pasados
            no son un indicador fiable de rendimientos futuros, y que no se puede asumir que cualquier inversión en Findex generará beneficios.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">7. Capacitación en Findex Edge</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Findex Edge es nuestro apartado de educación financiera, donde ofrecemos cursos, capacitaciones, seminarios y otros recursos educativos. Se recomienda
            encarecidamente que todos los usuarios completen estos cursos antes de tomar cualquier decisión significativa de inversión en la plataforma.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">8. Modificaciones a los Términos y Condiciones</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Findex se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento. Los cambios se notificarán a través de la plataforma,
            y es responsabilidad del usuario revisar periódicamente los términos para asegurarse de estar al tanto de cualquier modificación.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">9. Legislación Aplicable</h2>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Estos Términos y Condiciones se rigen por las leyes aplicables en México, y cualquier disputa o reclamación derivada del uso de nuestros servicios
            será resuelta en los tribunales competentes de dicha jurisdicción.
          </p>
          <p className="text-lg text-gray-300" data-aos="fade-up">
            Al aceptar estos términos, usted declara que comprende plenamente los riesgos asociados con los productos de Findex y que asume la responsabilidad
            de sus decisiones de inversión.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
