import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [faqOpen, setFaqOpen] = useState(null); // Para controlar el acordeón de FAQ
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const faqs = [
    {
      question: "1. ¿Qué es Findex y cómo funciona?",
      answer: `Findex es un modelo que tiene como objetivo convertirse en una sociedad anónima de inversión descentralizada, 
      mediante la implementación de crypto. Findex se divide en dos segmentos principales: La capitalización y la innovación (Planes Findex y Crowdfunding de Proyectos Productivos).
      
      En capitalización existen: Los Planes Findex; es una operativa de smart money concepts e inducción de liquidez en mercados de divisas y commodities, semi automatizada. 
      Mientras que en Innovación: El segmento de Crowdfunding permite a los usuarios crear su propio proyecto productivo y a los demás la posibilidad de invertir en él.`
    },
    {
      question: "2. ¿Cómo funcionan los Planes Findex?",
      answer: `Los Planes Findex son oportunidades de inversión que generan rendimientos mensuales compuestos de hasta un 11%. Existen tres niveles de planes:
      
      - F500: Inversión de $500 a $4,900 USD, con un rendimiento promedio de hasta el 7% mensual.
      - F5000: Inversión de $5,000 a $9,900 USD, con un rendimiento promedio de hasta el 9% mensual.
      - F10K: Inversión de $10,000 USD en adelante, con un rendimiento promedio de hasta el 11% mensual.`
    },
    {
      question: "3. ¿Qué es el Crowdfunding en Findex?",
      answer: `El Crowdfunding en Findex permite a los usuarios invertir en proyectos productivos tangibles e intangibles. Los proyectos pasan por un riguroso proceso de selección 
      para asegurar su viabilidad y rentabilidad. Los usuarios también pueden presentar sus propios proyectos para recibir financiamiento.`
    },
    {
      question: "4. ¿Qué es el sistema de Marketing de Referidos?",
      answer: `El sistema de Marketing de Referidos de Findex recompensa a los usuarios con comisiones empresariales y bonos residuales por metas alcanzadas.`
    },
    {
      question: "5. ¿Qué son los Bonos Residuales y cómo se ganan?",
      answer: `Los Bonos Residuales son recompensas mensuales obtenidas al alcanzar ciertos niveles de inversión y afiliación en la plataforma.`
    },
    {
      question: "6. ¿Cuáles son las reglas para retirar fondos en Findex?",
      answer: `Los fondos invertidos en los Planes Findex deben mantenerse durante un mínimo de 6 meses. Si se retira antes de este plazo, se aplica una comisión del 40%.
      
      - Solo se permite un retiro por semana y un máximo de tres retiros por mes.
      - Cada retiro lleva una comisión del 3% del monto retirado.`
    },
    {
      question: "7. ¿Qué medidas de seguridad ofrece Findex?",
      answer: `Findex implementa medidas de seguridad estrictas tanto para la plataforma digital como para la selección de proyectos productivos. Las transacciones están protegidas 
      mediante encriptación avanzada y los proyectos han sido evaluados rigurosamente.`
    },
    {
      question: "8. ¿Cómo se seleccionan los proyectos de Crowdfunding?",
      answer: `Los proyectos pasan por un proceso de selección exhaustivo que incluye análisis financiero, evaluación de riesgos y revisión del equipo detrás del proyecto.`
    },
    {
      question: "9. ¿Qué ocurre si un proyecto de Crowdfunding no cumple con sus objetivos?",
      answer: `Findex ofrece mecanismos de protección para los inversores, como opciones de retiro anticipado o reinversión en otros proyectos, dependiendo del caso.`
    },
    {
      question: "10. ¿Cómo puedo unirme a Findex?",
      answer: `Para unirse a Findex, regístrate en nuestra plataforma, elige el plan de inversión o proyecto de Crowdfunding que te interese y comienza a invertir.`
    },
    {
      question: "11. ¿Qué soporte ofrece Findex a sus usuarios?",
      answer: `Findex ofrece soporte completo, incluyendo asistencia en la configuración de cuentas y selección de inversiones. Se puede contactar al equipo por chat, correo o teléfono.`
    },
    {
      question: "12. ¿Existen riesgos asociados con las inversiones en Findex?",
      answer: `Sí, como en cualquier inversión, existe un nivel de riesgo. Aunque Findex selecciona rigurosamente los proyectos, los resultados pueden variar.`
    },
    {
      question: "13. ¿Qué medidas de seguridad toma Findex para proteger mi inversión?",
      answer: `Findex implementa estándares de seguridad estrictos y auditorías internas para proteger tanto los fondos como la información personal de los usuarios.`
    },
    {
      question: "14. ¿Cómo puedo saber si un proyecto en el apartado de crowdfunding es confiable?",
      answer: `Cada proyecto pasa por un proceso de evaluación exhaustivo que garantiza su viabilidad y rentabilidad. Se ofrece un análisis financiero completo de cada proyecto.`
    },
    {
      question: "15. ¿Puedo retirar mi capital en cualquier momento?",
      answer: `El capital invertido está sujeto a un período de retención de 6 meses. Los retiros antes de este plazo están sujetos a una comisión del 40%.`
    },
    {
      question: "16. ¿Cuál es el proceso para cargar un proyecto productivo en la plataforma?",
      answer: `Los usuarios deben seguir un formato estricto que garantiza que el proyecto cumpla con todos los requisitos de viabilidad y rentabilidad. El equipo de Findex revisa cada proyecto.`
    },
    {
      question: "17. ¿Qué sucede si un proyecto productivo en el que invertí no cumple con mis expectativas?",
      answer: `Findex realiza una evaluación exhaustiva de cada proyecto antes de permitir su participación, pero los resultados pueden no coincidir con las expectativas.`
    },
    {
      question: "19. ¿Puedo cambiar mi plan Findex una vez que he invertido?",
      answer: `No, pero se puede adicionar un nuevo plan desde el apartado planes en tu back office.`
    },
    {
      question: "20. ¿Cómo se calculan los rendimientos en los planes Findex?",
      answer: `Los rendimientos se calculan en base a los parámetros de cada plan, se actualizan visualmente a diario y se componen mensualmente, variando entre 7% y 11%.`
    },
    {
      question: "21. ¿Qué debo hacer si tengo problemas técnicos o dudas sobre mi cuenta?",
      answer: `Puedes contactar al equipo de soporte a través de la sección de ayuda en la plataforma o enviando un correo electrónico.`
    },
    {
      question: "22. ¿Cómo se generan los beneficios en los planes Findex?",
      answer: `Se generan a través de un algoritmo que implementa Smart Money Concepts (SMC), facilitando decisiones basadas en teoría avanzada de mercado.`
    },
    {
      question: "23. ¿Qué es la teoría LIT y cómo se aplica en Findex?",
      answer: `La teoría LIT (Liquidity Inducement Theory) estudia cómo los grandes actores manipulan la liquidez. En Findex, se utiliza para identificar oportunidades de alta probabilidad.`
    },
    {
      question: "24. ¿Qué tipo de análisis se realiza en Findex antes de tomar posiciones?",
      answer: `Cada semana, los analistas de Findex realizan un análisis detallado del mercado que se publica en la plataforma, mostrando estrategias empleadas y resultados.`
    },
    {
      question: "25. ¿Cómo se mitigan los riesgos en la operativa de Findex?",
      answer: `Los riesgos se mitigan mediante estrategias avanzadas y un estricto control del drawdown, que se mantiene por debajo del 3.5%.`
    },
    {
      question: "26. ¿Puedo ver las transacciones realizadas en mi cuenta?",
      answer: `Sí, en la plataforma de Findex puedes ver las transacciones realizadas diariamente en el apartado de "Operativa".`
    },
    {
      question: "27. ¿Qué sucede si los mercados experimentan una alta volatilidad?",
      answer: `Findex ajusta las estrategias y optimiza el algoritmo para adaptarse a las nuevas condiciones del mercado sin comprometer significativamente los rendimientos.`
    },
    {
      question: "28. ¿Cómo puedo seguir el desempeño de mi inversión en Findex?",
      answer: `El desempeño se actualiza visualmente en la plataforma todos los días, y los retiros y actualizaciones de los planes se realizan semanalmente.`
    },
    {
      question: "29. ¿Qué es el drawdown y cómo afecta a mi inversión?",
      answer: `El drawdown es la reducción desde el pico más alto hasta el punto más bajo de tu cuenta antes de que recupere el valor máximo. En Findex, el drawdown se mantiene por debajo del 3.5%.`
    },
    {
      question: "30. ¿Qué respaldo tiene el algoritmo utilizado en Findex?",
      answer: `El algoritmo de Findex ha sido desarrollado en Londres, basado en Smart Money Concepts, y cuenta con un historial auditado que respalda su efectividad.`
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white relative">
      {/* Barra de Navegación */}
      <header className="flex items-center justify-between px-10 py-6">
        <div className="flex items-center space-x-4">
          <img src="/images/toro.png" alt="Findex Logo" className="w-32" />
          <nav className="hidden md:flex space-x-8 text-lg">
            <a href="#nosotros" className="hover:text-gray-300">Nosotros</a>
            <a href="#edge" className="hover:text-gray-300">Edge</a>
            <a href="#crowd" className="hover:text-gray-300">Crowd</a>
            <a href="#de-finders" className="hover:text-gray-300">De-Finders</a>
            <a href="#faq" className="hover:text-gray-300">FAQ</a> {/* Nuevo enlace a FAQ */}
          </nav>
        </div>

        <div className="flex items-center space-x-4">
          <button 
            className="bg-teal-400 text-black px-6 py-2 rounded-full text-lg font-semibold hover:bg-teal-500"
            onClick={() => navigate('/login')}
          >
            LOG IN
          </button>
          <button 
            className="text-white md:hidden p-2"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </header>

      {/* Menú desplegable para dispositivos móviles */}
      {menuOpen && (
        <div className="absolute inset-0 bg-black text-white p-8 z-50 flex flex-col">
          <nav className="flex flex-col space-y-6 text-lg">
            <a href="#nosotros" className="hover:text-gray-300">Nosotros</a>
            <a href="#edge" className="hover:text-gray-300">Edge</a>
            <a href="#crowd" className="hover:text-gray-300">Crowd</a>
            <a href="#de-finders" className="hover:text-gray-300">De-Finders</a>
            <a href="#faq" className="hover:text-gray-300">FAQ</a> {/* Nuevo enlace a FAQ */}
          </nav>
        </div>
      )}

      {/* Sección Principal con Imagen de Fondo */}
      <section 
        className="flex flex-col items-center justify-center text-center py-64 bg-cover bg-center bg-no-repeat w-full h-screen"
        style={{ backgroundImage: "url('/images/5.jpg')" }}
      >
        <h1 className="text-6xl font-bold">CREEMOS</h1>
        <p className="text-2xl mt-4">que la innovación impulsa nuestro futuro</p>
      </section>

      {/* Sección: Nosotros */}
      <section id="nosotros" className="flex flex-col md:flex-row items-center justify-center text-left py-32 bg-black text-white">
        <div className="md:w-1/2 px-10">
          <h2 className="text-5xl font-bold mb-4">NOSOTROS</h2>
          <h3 className="text-2xl font-semibold mb-4">Somos De-Finders, Visionarios del Éxito.</h3>
          <p className="text-lg">
            Somos una comunidad comprometida con principios sólidos: Integridad, Excelencia, Responsabilidad y Colaboración. 
            Estos valores son la base que sostiene nuestra misión. A través de herramientas innovadoras y estrategias eficaces, 
            nos dedicamos a alcanzar y superar nuestros objetivos empresariales, siempre con un enfoque en el éxito compartido.
          </p>
        </div>
        <div className="md:w-1/2 flex justify-center mt-10 md:mt-0">
          <img src="/images/login.png" alt="App Mockup" className="w-64 h-auto border-2 border-teal-400 rounded-md" />
        </div>
      </section>

      {/* Sección: Findex Edge */}
      <section 
        id="edge" 
        className="flex flex-col items-center justify-center text-center py-64 bg-cover bg-center bg-no-repeat w-full h-screen"
        style={{ backgroundImage: "url('/images/2.jpg')" }}
      >
        <h2 className="text-5xl font-bold text-teal-400">FINDEX EDGE</h2>
        <p className="text-2xl text-teal-200 mt-4">Transforma tu potencial.</p>
      </section>

      {/* Sección: Amplía tu conocimiento con nuestra formación */}
      <section id="findex-academy" className="bg-black text-white py-16 text-left">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
            <h2 className="text-4xl font-bold mb-4">Amplía tu conocimiento con nuestra formación</h2>
            <p className="text-lg">
              Nuestra formación de vanguardia Findex Edge es tu puerta de entrada a un aprendizaje de excelencia. 
              Nuestra plataforma te brinda acceso a formación de alto nivel, diseñada para potenciar tu desarrollo profesional y liderazgo. 
              Con contenidos innovadores y relevantes, Findex Edge te prepara para afrontar los retos del futuro con confianza. 
              Da el siguiente paso en tu carrera y adquiere las habilidades que marcarán la diferencia.
            </p>
          </div>
        </div>
      </section>

      {/* Sección: Desarrolla */}
      <section 
        id="develop" 
        className="flex flex-col items-center justify-center text-center py-64 bg-cover bg-center bg-no-repeat w-full h-screen"
        style={{ backgroundImage: "url('/images/3.jpg')" }}
      >
        <h2 className="text-5xl font-bold text-white">DESARROLLA</h2>
        <p className="text-2xl text-gray-300 mt-4">
          Proyectos que cumplan con los estándares y obtén financiamiento de la comunidad tras ser evaluados.
        </p>
      </section>

      {/* Sección: Preguntas Frecuentes */}
      <section id="faq" className="bg-black text-white py-16 px-10">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold mb-8 text-center">Preguntas Frecuentes</h2>

          {/* Acordeón para FAQ */}
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-gray-800 p-4 rounded-lg">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFaq(index)}
                >
                  <h3 className="text-xl font-semibold">{faq.question}</h3>
                  {faqOpen === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {faqOpen === index && (
                  <p className="mt-4 text-gray-300">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
