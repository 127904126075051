import React, { useState } from 'react';
import { FaGlobe } from 'react-icons/fa'; // Importar el ícono del mundo
import { useLanguage } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';

function Login() {
  const { getText, setLanguage } = useLanguage();
  const [form, setForm] = useState({
    emailOrUsername: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!form.emailOrUsername || !form.password) {
      setError(getText('formError'));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.message);
        return;
      }

      const responseData = await response.json();
      localStorage.setItem('token', responseData.token);
      localStorage.setItem('role', responseData.role); // Asegúrate de guardar el rol

      // Verifica y corrige la URL antes de usar navigate
      const redirectUrl = new URL(responseData.redirectUrl);

      // Asegurarnos que redirija bien al pathname
      navigate(redirectUrl.pathname + redirectUrl.search + redirectUrl.hash);

    } catch (err) {
      setError(getText('formError'));
    }
  };

  return (
    <div 
      className="relative min-h-screen flex items-center justify-center bg-cover bg-center" 
      style={{ backgroundImage: "url('/images/fondo.png')" }}
      >
      <div className="absolute top-4 right-4">
        <div className="relative inline-block text-left">
          <FaGlobe
            className="text-white cursor-pointer"
            size={24}
            onClick={() => setShowLanguageOptions(!showLanguageOptions)}
          />
          {showLanguageOptions && (
            <div className="absolute right-0 mt-2 w-24 bg-gray-700 rounded-md shadow-lg">
              <button onClick={() => setLanguage('en')} className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-600">EN</button>
              <button onClick={() => setLanguage('es')} className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-600">ES</button>
            </div>
          )}
        </div>
      </div>

      <div className="bg-black bg-opacity-70 p-8 rounded-md shadow-lg max-w-md w-full text-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img src="/images/toro.png" alt="Findex Logo" className="mx-auto w-32 mb-6" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="emailOrUsername" className="block text-sm font-medium leading-6 text-white">
                {getText('emailOrUsername')}
              </label>
              <div className="mt-2">
                <input
                  id="emailOrUsername"
                  name="emailOrUsername"
                  type="text"
                  required
                  autoComplete="username"
                  value={form.emailOrUsername}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                  {getText('password')}
                </label>
                <div className="text-sm">
                  <a href="#" className="font-semibold text-green-600 hover:text-green-500">
                    {getText('forgotPassword')}
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  value={form.password}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                {getText('login')}
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-300">
            {getText('notAMember')} {' '}
            <a href="/register" className="font-semibold leading-6 text-green-600 hover:text-green-500">
              {getText('registerHere')}
            </a>
          </p>
        </div>

        {error && <p className="text-red-500 text-center mt-4">{error}</p>}
      </div>
    </div>
  );
}

export default Login;
