import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUsers, FaList, FaCog, FaMoneyBillWave, FaPercentage } from 'react-icons/fa'; // Añadir FaPercentage para el ícono de comisiones
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar'; 

const AdminPortal = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <NavigationBar userName="Admin" />

      <div className="p-6 space-y-8">
        {/* Gestión de Usuarios */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
          <FaUsers className="text-6xl text-blue-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-white">Gestión de Usuarios</h2>
          <p className="text-gray-400 mt-2">Visualiza, edita y elimina usuarios del sistema.</p>
          <button 
            className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
            onClick={() => handleNavigation('/admin/users')}
          >
            Ver Usuarios
          </button>
        </div>

        {/* Gestión de Planes */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
          <FaList className="text-6xl text-green-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-white">Gestión de Planes</h2>
          <p className="text-gray-400 mt-2">Revisa y aprueba las compras de planes.</p>
          <button 
            className="mt-4 px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
            onClick={() => handleNavigation('/admin/plans')}
          >
            Ver Planes
          </button>
        </div>

        {/* Gestión de Retiros */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
          <FaMoneyBillWave className="text-6xl text-yellow-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-white">Gestión de Retiros</h2>
          <p className="text-gray-400 mt-2">Revisa y aprueba las solicitudes de retiro.</p>
          <button 
            className="mt-4 px-6 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-500"
            onClick={() => handleNavigation('/admin/withdrawals')}
          >
            Ver Retiros
          </button>
        </div>

        {/* Comisiones por Referidos */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
          <FaPercentage className="text-6xl text-purple-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-white">Comisiones por Referidos</h2>
          <p className="text-gray-400 mt-2">Consulta las comisiones generadas por referidos y prepara los pagos.</p>
          <button 
            className="mt-4 px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-500"
            onClick={() => handleNavigation('/admin/commissions')}
          >
            Ver Comisiones
          </button>
        </div>

        {/* Configuraciones */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
          <FaCog className="text-6xl text-red-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-white">Configuraciones</h2>
          <p className="text-gray-400 mt-2">Ajusta configuraciones generales del sistema.</p>
          <button 
            className="mt-4 px-6 py-2 bg-red-600 text-white rounded-md hover:bg-red-500"
            onClick={() => handleNavigation('/admin/settings')}
          >
            Configuraciones
          </button>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AdminPortal;
