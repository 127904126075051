import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { FaEdit, FaSave } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';
import NavigationBar from './NavigationBar'; // Importamos la barra de navegación

const UserProfile = () => {
  const { getText } = useLanguage();
  const [isEditing, setIsEditing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [userData, setUserData] = useState({
    profileImage: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    wallet: '',
  });

  // Configurar AWS S3
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1' // Cambia a tu región
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    // Obtener la URL base de la API desde las variables de entorno
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // Solicitud al backend para obtener la información del usuario
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/user-profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUserData((prevData) => ({
      ...prevData,
      profileImage: file
    }));
  };

  const uploadToS3 = async () => {
    if (!userData.profileImage) return;

    setUploading(true);

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: `profile-images/${userData.profileImage.name}`,
      Body: userData.profileImage,
      ContentType: userData.profileImage.type
    };

    try {
      const uploadResponse = await s3.upload(params).promise();
      console.log('Imagen subida exitosamente:', uploadResponse.Location);

      // Actualizar el profileImage con la URL de S3
      setUserData((prevData) => ({
        ...prevData,
        profileImage: uploadResponse.Location
      }));
    } catch (error) {
      console.error('Error subiendo la imagen:', error);
    }

    setUploading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // Si hay una imagen nueva, sube la imagen a S3
    if (userData.profileImage && typeof userData.profileImage !== 'string') {
      await uploadToS3(); // Sube la imagen y actualiza la URL
    }

    try {
      const response = await fetch(`${apiBaseUrl}/user-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(userData),
      });
      if (response.ok) {
        setIsEditing(false);
        alert(getText('profileUpdated'));
      } else {
        alert(getText('profileUpdateFailed'));
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert(getText('profileUpdateFailed'));
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Barra de navegación */}
      <NavigationBar userName={userData.first_name} />

      <div className="p-6">
        <div className="max-w-3xl mx-auto bg-gray-800 p-6 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-center text-green-600 mb-4">{getText('profile')}</h1>
          <div className="flex flex-col items-center space-y-4">
            <div className="flex flex-col items-center">
              <img
                src={typeof userData.profileImage === 'string' ? userData.profileImage : 'https://via.placeholder.com/150'}
                alt="Profile"
                className="w-32 h-32 rounded-full object-cover"
              />
              {isEditing && (
                <input
                  type="file"
                  name="profileImage"
                  accept="image/*"
                  className="mt-2 text-gray-300"
                  onChange={handleImageChange}
                />
              )}
            </div>
            <div className="w-full space-y-4">
              <div>
                <label className="block text-sm font-medium text-blue-700">{getText('firstName')}</label>
                <input
                  type="text"
                  name="first_name"
                  value={userData.first_name}
                  onChange={handleInputChange}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm text-black focus:ring-green-500 focus:border-green-500 sm:text-sm ${!isEditing && 'bg-gray-800 text-white'}`}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-blue-700">{getText('lastName')}</label>
                <input
                  type="text"
                  name="last_name"
                  value={userData.last_name}
                  onChange={handleInputChange}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm text-black focus:ring-green-500 focus:border-green-500 sm:text-sm ${!isEditing && 'bg-gray-800 text-white'}`}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-blue-700">{getText('phoneNumber')}</label>
                <input
                  type="text"
                  name="phone_number"
                  value={userData.phone_number}
                  onChange={handleInputChange}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm text-black focus:ring-green-500 focus:border-green-500 sm:text-sm ${!isEditing && 'bg-gray-800 text-white'}`}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-blue-700">{getText('email')}</label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm text-black focus:ring-green-500 focus:border-green-500 sm:text-sm ${!isEditing && 'bg-gray-800 text-white'}`}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-blue-700">{getText('wallet')}</label>
                <input
                  type="text"
                  name="wallet"
                  value={userData.wallet}
                  onChange={handleInputChange}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm text-black focus:ring-green-500 focus:border-green-500 sm:text-sm ${!isEditing && 'bg-gray-800 text-white'}`}
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              {isEditing ? (
                <button
                  className="flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
                  onClick={handleSave}
                >
                  <FaSave className="mr-2" />
                  {getText('save')}
                </button>
              ) : (
                <button
                  className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                  onClick={() => setIsEditing(true)}
                >
                  <FaEdit className="mr-2" />
                  {getText('edit')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
