import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { ToastContainer, toast } from 'react-toastify';
import { FaTimes, FaGlobe } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import { useLanguage } from '../contexts/LanguageContext';

function Plans() {
  const { getText, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const [showModalStep1, setShowModalStep1] = useState(false);
  const [showModalStep2, setShowModalStep2] = useState(false);
  const [showFindexPlansModal, setShowFindexPlansModal] = useState(false);
  const [depositAmount, setDepositAmount] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanAmount, setSelectedPlanAmount] = useState(0);
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);

  const handlePlanClick = () => {
    setShowFindexPlansModal(true);
  };

  const handleFindexPlanSelect = (planId, amount) => {
    setSelectedPlan(planId);
    setSelectedPlanAmount(amount);
    setShowFindexPlansModal(false);
    setShowModalStep1(true);
  };

  const handleStep1Close = () => {
    setShowModalStep1(false);
  };

  const handleStep1Next = () => {
    // Ajuste: Validación para que el monto ingresado sea mayor o igual al monto del plan seleccionado
    if (parseFloat(depositAmount) < selectedPlanAmount) {
      toast.error("La cantidad ingresada debe ser mayor o igual al valor del plan", {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }
    setShowModalStep1(false);
    setShowModalStep2(true);
  };

  const handleStep2Close = () => {
    setShowModalStep2(false);
  };

  const handleDepositClick = async () => {
    const token = localStorage.getItem('token'); 
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/purchase-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          planId: selectedPlan,
          amount: depositAmount
        })
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Mostrar mensaje con Toastify
        toast.success(
          "La compra de tu plan está en proceso de validación. En un tiempo máximo de 2 horas podrás consultar tu saldo. Bienvenido a Findex",
          {
            position: "top-center",
            autoClose: 5000,
          }
        );
  
        // Redirigir al nuevo componente después de 2 segundos
        setTimeout(() => {
          navigate('/processpay');  // Aquí rediriges al componente "sala de espera"
        }, 2000);
      } else {
        toast.error(data.message || "Error al procesar la compra del plan", {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error("Error de red al procesar la compra del plan", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  
    handleStep2Close();
  };

  const qrCodeValue = 'TNoYytnT6T7Cky3wUVQijWT5JwtRErf3sD';

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col">
      {/* Navigation Bar */}
      <div className="flex justify-between items-center p-4">
        <h1 className="text-xl font-bold text-green-600">Findex</h1>
        <div className="relative">
          <FaGlobe 
            className="text-xl text-white cursor-pointer" 
            onClick={() => setShowLanguageOptions(!showLanguageOptions)}
          />
          {showLanguageOptions && (
            <div className="absolute top-full right-0 mt-2 w-32 rounded-md shadow-lg bg-white text-gray-700">
              <div className="py-1">
                <button
                  onClick={() => {
                    setLanguage('en');
                    setShowLanguageOptions(false);
                  }}
                  className="block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
                >
                  EN
                </button>
                <button
                  onClick={() => {
                    setLanguage('es');
                    setShowLanguageOptions(false);
                  }}
                  className="block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
                >
                  ES
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex-grow flex flex-col justify-center items-center">
        <div className="bg-gray-800 p-10 rounded-lg shadow-lg max-w-xl w-full sm:max-w-2xl">
          <h2 className="text-3xl font-bold text-green-600">{getText('findexproTitle')}</h2>
          <p className="text-gray-400 mt-4">{getText('findexproDescription')}</p>
          <button 
            onClick={handlePlanClick} 
            className="mt-6 px-8 py-3 bg-blue-600 text-white text-lg rounded-md hover:bg-blue-500"
          >
            {getText('select')}
          </button>
        </div>
      </div>

      {/* Modals */}
      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showFindexPlansModal ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-white">{getText('selectFindexPlan')}</h2>
            <FaTimes className="text-white cursor-pointer" onClick={() => setShowFindexPlansModal(false)} />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
            <div className="text-center">
              <h5 className="text-green-600">F500+</h5>
              <p className="text-gray-400">7% {getText('annual')}</p>
              <button 
                className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() => handleFindexPlanSelect(2, 500)}
              >
                {getText('buyF500')}
              </button>
            </div>
            <div className="text-center">
              <h5 className="text-green-600">F5000+</h5>
              <p className="text-gray-400">9% {getText('annual')}</p>
              <button 
                className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() => handleFindexPlanSelect(3, 5000)}
              >
                {getText('buyF5000')}
              </button>
            </div>
            <div className="text-center">
              <h5 className="text-green-600">F10K+</h5>
              <p className="text-gray-400">11% {getText('annual')}</p>
              <button 
                className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() => handleFindexPlanSelect(4, 10000)}
              >
                {getText('buyF10K')}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showModalStep1 ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-end">
            <FaTimes className="text-white cursor-pointer" onClick={handleStep1Close} />
          </div>
          <h2 className="text-xl font-bold text-white mb-4">{getText('enterDepositAmount')}</h2>
          <input 
            type="number" 
            className="w-full p-2 rounded-md bg-gray-700 text-white border-none"
            value={depositAmount} 
            onChange={(e) => setDepositAmount(e.target.value)} 
            placeholder={getText('enterAmount')}
          />
          <button 
            className="mt-4 w-full px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
            onClick={handleStep1Next}
          >
            {getText('next')}
          </button>
        </div>
      </div>

      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showModalStep2 ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-end">
            <FaTimes className="text-white cursor-pointer" onClick={handleStep2Close} />
          </div>
          <div className="text-center">
            <p className="text-red-500 mb-2">Verifica al momento de hacer el depósito que la wallet y la red sean las correctas</p>
            <QRCode value={qrCodeValue} className="mx-auto" />
            <p className="text-gray-400 mt-4">Wallet Address: {qrCodeValue}</p>
            <p className="text-gray-400 mt-2">USDT (TRON)</p>
            <button 
              className="mt-4 w-full px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
              onClick={handleDepositClick}
            >
              Depositar
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Plans;
