import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';  // Importar el ícono de palomita
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {
  const [form, setForm] = useState({
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    confirmPassword: '',
    birthdate: '',
    referralCode: '',
    phoneNumber: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsClicked, setTermsClicked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ref = searchParams.get('ref');

    if (ref) {
      setForm((prevForm) => ({ ...prevForm, referralCode: ref }));
    }
  }, [location.search]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!termsAccepted) {
      setError('Debes aceptar los términos y condiciones para continuar');
      toast.error('Debes aceptar los términos y condiciones para continuar');
      return;
    }

    if (!form.email.includes('@')) {
      setError('El correo electrónico es requerido');
      toast.error('El correo electrónico es requerido');
      return;
    }
    if (form.password !== form.confirmPassword) {
      setError('Las contraseñas no coinciden');
      toast.error('Las contraseñas no coinciden');
      return;
    }
    if (form.password.length < 8 || !/[!@#$%^&*]/.test(form.password)) {
      setError('La contraseña debe tener al menos 8 caracteres y un símbolo especial');
      toast.error('La contraseña debe tener al menos 8 caracteres y un símbolo especial');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.message);
        toast.error(responseData.message);
        return;
      }

      setSuccess('Registro exitoso');
      toast.success('Registro exitoso');
      setForm({
        email: '',
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        confirmPassword: '',
        birthdate: '',
        referralCode: '',
        phoneNumber: '',
      });
      navigate('/login');
    } catch (err) {
      setError('Hubo un error con el registro');
      toast.error('Hubo un error con el registro');
    }
  };

  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: "url('/images/fondo.png')" }}
    >
      {/* Selector de idioma (si es necesario) */}
      <div className="absolute top-4 right-4">
        <div className="relative inline-block text-left">
          {/* Puedes agregar tu selector de idioma aquí */}
        </div>
      </div>

      {/* Contenedor principal del formulario */}
      <div className="bg-black bg-opacity-70 p-8 rounded-md shadow-lg max-w-md w-full text-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img src="/images/toro.png" alt="Findex Logo" className="mx-auto w-32 mb-6" />
        </div>

        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-white">
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-white">
                Nombre
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={form.firstName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-white">
                Apellido
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={form.lastName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-white">
                Nombre de usuario
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={form.username}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-white">
                Teléfono
              </label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={form.phoneNumber}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="birthdate" className="block text-sm font-medium text-white">
                Fecha de nacimiento
              </label>
              <input
                type="date"
                id="birthdate"
                name="birthdate"
                value={form.birthdate}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="referralCode" className="block text-sm font-medium text-white">
                Código de referido
              </label>
              <input
                type="text"
                id="referralCode"
                name="referralCode"
                value={form.referralCode}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-white">
                Contraseña
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={form.password}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-white">
                Confirmar contraseña
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
              />
            </div>
          </div>

          {/* Checkbox de términos y condiciones con la palomita */}
          <div className="flex items-start my-4">
            <div className="h-4 w-4 rounded-sm bg-gray-700 flex items-center justify-center cursor-pointer" onClick={() => setTermsAccepted(!termsAccepted)}>
              {termsAccepted && <FaCheck className="text-green-500" />} {/* Palomita cuando está aceptado */}
            </div>
            <label htmlFor="acceptTerms" className="ml-2 block text-sm text-gray-200">
              Acepto los{' '}
              <span
                className="text-green-500 cursor-pointer"
                onClick={() => {
                  navigate('/terms-and-conditions');
                  setTermsClicked(true);
                }}
              >
                términos y condiciones
              </span>
            </label>
          </div>

          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          {success && <p className="text-green-500 text-sm mt-2">{success}</p>}

          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Registrarse
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
