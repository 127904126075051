import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { FaCloudUploadAlt } from 'react-icons/fa';  // Icono de subida
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar';

const AdminSettings = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  // Configurar el SDK de AWS con las credenciales y región
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1',  // Asegúrate de cambiar esto si estás en otra región
  });

  const s3 = new AWS.S3();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (!selectedFile) {
      toast.error("Por favor selecciona un archivo para subir.");
      return;
    }

    setIsUploading(true);

    const fileName = `${new Date().toISOString().split('T')[0]}-${selectedFile.name}`;  // Nombre de archivo con fecha
    const params = {
      Bucket: process.env.REACT_APP_S3_OPERATIVA_BUCKET_NAME,
      Key: fileName,
      Body: selectedFile,
    };

    s3.upload(params, (err, data) => {
      setIsUploading(false);
      if (err) {
        toast.error('Error al subir el archivo.');
        console.error('Error uploading file:', err);
      } else {
        toast.success('Archivo subido exitosamente.');
        console.log('File uploaded successfully:', data);
      }
    });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <NavigationBar userName="Administrador" />

      <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
        <FaCloudUploadAlt className="text-6xl text-blue-500 mx-auto mb-4" />
        <h2 className="text-2xl font-bold">Subir Imagen Operativa</h2>
        <input type="file" onChange={handleFileChange} className="mt-4" />
        <button
          onClick={handleFileUpload}
          className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 disabled:opacity-50"
          disabled={isUploading}
        >
          {isUploading ? 'Subiendo...' : 'Subir Imagen'}
        </button>
      </div>

      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default AdminSettings;
