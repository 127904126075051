import Login from "./components/Login";

const texts = {
    en: {
      homeTitle: "Welcome to Findex",
      homeSubtitle: "Facilitating our clients in achieving their business and financial goals through an innovative combination of semi-automated operations in global markets and crowdfunding.",
      getStarted: "Get Started",
      numbersTitle: "Findex in Numbers",
      users: "Users",
      supportedCountries: "Supported Countries",
      assetsUnderManagement: "Assets Under Management",
      whatIsTitle: "What is Findex?",
      whatIsDescription: "Findex is an innovative crowdfunding ecosystem that brings together investors, entrepreneurs, and traders in the world of currency and finance. Our products provide unique opportunities to invest in promising projects, follow the transactions of successful traders, participate in the development of financial networks, and much more.",
      becomeInvestor: "Become an Investor",
      plansTitle: "Explore Our Plans",
      retirementPlanTitle: "Retirement Plan",
      retirementPlanDescription: "Our retirement plan is designed with conservative parameters for those who see financial solidity as a vital part of their lives. Retirement plans allow us to plan for a dignified long-term retirement.",
      retirementPlanFeature1: "Start from 500 USD",
      retirementPlanFeature2: "2% monthly compound benefit",
      retirementPlanFeature3: "Option to add more resources to the initial capital",
      retirementPlanFeature4: "Conservative operation",
      findexPlanTitle: "Findex Plan",
      findexPlanDescription: "The Findex operation is designed with moderate parameters for investors planning a project that requires significant capital investment over the medium term or to cover monthly responsibilities.",
      findexPlanFeature1: "Start from 500 USD",
      findexPlanFeature2: "Up to 12% monthly benefit",
      findexPlanFeature3: "Monthly benefit withdrawals",
      findexPlanFeature4: "Semi-automated operation",
      howWeDoItTitle: "How We Do It",
      howWeDoItDescription: "By integrating Smart Money Concepts and implementing a semi-automated algorithm to take positions in the currency and commodity markets.",
      marketDataTitle: "Popular financial assets",
      firstName: "First Name",
      lastName: "Last Name",
      username: "Username",
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      birthdate: "Birthdate",
      referralCode: "Referral Code",
      firstNameRequired: "First Name is required",
      lastNameRequired: "Last Name is required",
      usernameRequired: "Username is required",
      emailRequired: "Email is required",
      passwordRequired: "Password is required",
      passwordInvalid: "Password must be at least 8 characters long and contain letters, numbers, and special characters",
      passwordMismatch: "Passwords do not match",
      birthdateRequired: "Birthdate is required",
      referralCodeInvalid: "Referral Code must be alphanumeric and 6 characters long",
      formError: "Please fix the errors in the form",
      registrationSuccess: "Registration successful",
      register: "Register",
      language: "Language",
      bonusesTitle: "Bonuses",
    level: "Level",
    totalAccumulated: "Total Accumulated",
    minPlan: "Minimum Plan",
    monthlyBonus: "Monthly Bonus",
    structureRequirements: "Structure Requirements",
    f1: "3 Affiliates",
    f2: "3 Affiliates",
    f3: "1 F2",
    f4: "1 F3 + 1 F2",
    f5: "1 F4 + 1 F3",
    f6: "1 F5 + 1 F4 + 1 F3",
    f7: "1 F6 + 1 F5 + 1 F4 + 1 F3",
    f8: "1 F7 + 1 F6 + 2 F5",
    loginTitle: "Login",
    selectPlan: "Select Your Plan",
    totalBalanceInvested: "Total Balance Invested",
    currentBalance: "Current Balance",
    referralBonus: "Referral Bonus",
    emailOrUsername: "Email or Username",
    forgotPassword: "Forgot Password?",
    notAMember: "Not a Member?",
    registerHere: "Register Here",
    findexproTitle:"Findex Pro Plan",
    findexproDescription:"The Findex operation is designed with moderate parameters for investments that are planning a project that requires a significant capital investment in the medium term or to cover monthly liabilities",
    deposit:"Deposit",
    walletAddress:"Wallet Address",
    next:"Next",
    enterAmount:"Enter Amount",
    welcomeToSuccess:" Welcome to Success",
    welcomeFindex:"Welcome to Findex",
    alreadyHaveAccount:"Already Have Account? ",
    viewProfile:"View your Profile",
    logo:"Findex",
    referralLinkCopied:"Referral Link Copied",
    progressChart:"Progress Chart",
    selectFindexPlan:"Select Your Findex Plan",
    annual:"Monthly",
     usuariosReferidos:"Usuarios Referidos",
    capitalOrganizacion:"Capital de tu Organizacion",
    newProjects: "Conoce los proyectos de inversion de la comunidad, participa y obten rendimientos",
    myProjects: "Mis Proyectos",
    uploadProject: "Subir Proyecto",
    noUploadedProjects:"No tienes Proyectos Actualmente",
    investedProjects:"Proyectos Invertidos",
    project:"Proyecto",
    investedAmount:"Cantidad Invertida",
    noInvestedProjects:"No Cuentas Con Inversiones en Proyectos",
    status:"Status"









    },
    es: {
      homeTitle: "Bienvenido a Findex",
      homeSubtitle: "Facilitamos a nuestros clientes la consecución de sus objetivos empresariales y financieros mediante una combinación innovadora de operaciones semi-automatizadas en mercados globales y crowdfunding.",
      getStarted: "Comienza Ahora",
      numbersTitle: "Findex en Números",
      users: "Usuarios",
      supportedCountries: "Países Soportados",
      assetsUnderManagement: "Activos Bajo Gestión",
      whatIsTitle: "¿Qué es Findex?",
      whatIsDescription: "Findex es un ecosistema innovador de crowdfunding que reúne a inversores, emprendedores y comerciantes en el mundo de la moneda y las finanzas. Nuestros productos proporcionan oportunidades únicas para invertir en proyectos prometedores, seguir las transacciones de comerciantes exitosos, participar en el desarrollo de redes financieras, y mucho más.",
      becomeInvestor: "Conviértete en Inversor",
      plansTitle: "Explora Nuestros Planes",
      retirementPlanTitle: "Plan de Jubilación",
      retirementPlanDescription: "El plan de jubilación está diseñado con parámetros conservadores para aquellos para quienes crear solidez financiera es una parte vital de sus vidas. Los planes de retiro nos permiten planificar una jubilación digna a largo plazo.",
      retirementPlanFeature1: "Apertura desde 500 USD",
      retirementPlanFeature2: "Beneficio compuesto del 2% mensual",
      retirementPlanFeature3: "Posibilidad de adicionar más recursos al capital inicial",
      retirementPlanFeature4: "Operativa conservadora",
      findexPlanTitle: "Plan Findex",
      findexPlanDescription: "La operativa Findex está diseñada con parámetros moderados para inversores que están planificando un proyecto que requiera una inversión significativa de capital a mediano plazo o para cubrir responsabilidades mensuales.",
      findexPlanFeature1: "Apertura desde 500 USD",
      findexPlanFeature2: "Hasta 12% de beneficio mensual",
      findexPlanFeature3: "Retiros mensuales de beneficios",
      findexPlanFeature4: "Operativa semi-automatizada",
      howWeDoItTitle: "Cómo lo hacemos",
      howWeDoItDescription: "Mediante la integración de Smart Money Concepts y la implementación de un algoritmo semiautomatizado para tomar posición en el mercado de divisas y commodities.",
      marketDataTitle: "Activos financieros populares",
      firstName: "Nombre",
      lastName: "Apellido",
      username: "Nombre de usuario",
      email: "Correo electrónico",
      password: "Contraseña",
      confirmPassword: "Confirmar contraseña",
      birthdate: "Fecha de nacimiento",
      referralCode: "Código de referencia",
      firstNameRequired: "El nombre es obligatorio",
      lastNameRequired: "El apellido es obligatorio",
      usernameRequired: "El nombre de usuario es obligatorio",
      emailRequired: "El correo electrónico es obligatorio",
      passwordRequired: "La contraseña es obligatoria",
      passwordInvalid: "La contraseña debe tener al menos 8 caracteres y contener letras, números y caracteres especiales",
      passwordMismatch: "Las contraseñas no coinciden",
      birthdateRequired: "La fecha de nacimiento es obligatoria",
      referralCodeInvalid: "El código de referencia debe ser alfanumérico y tener 6 caracteres",
      formError: "Por favor, corrija los errores en el formulario",
      registrationSuccess: "Registro exitoso",
      register: "Registrarse",
      language: "Idioma",
      bonusesTitle: "Bonos",
    level: "Nivel",
    totalAccumulated: "Total Acumulado",
    minPlan: "Plan Mínimo",
    monthlyBonus: "Bono Mensual",
    structureRequirements: "Requisitos Estructura",
    f1: "3 Afiliados",
    f2: "3 Afiliados",
    f3: "1 F2",
    f4: "1 F3 + 1 F2",
    f5: "1 F4 + 1 F3",
    f6: "1 F5 + 1 F4 + 1 F3",
    f7: "1 F6 + 1 F5 + 1 F4 + 1 F3",
    f8: "1 F7 + 1 F6 + 2 F5",
    loginTitle: "Iniciar Sesion",
    selectPlan: "Selecciona tu Plan",
    totalBalanceInvested: "Balance Total Invertido",
    currentBalance: "Balance Actual",
    referralBonus: "Bonus por Referidos",
    emailOrUsername: "Correo Electronico o Nombre de Usuarios",
    forgotPassword: "Olvidaste tu Contraseña?",
    notAMember: "Aun no eres Miembro?",
    registerHere: "Registrate Aqui",
    findexproTitle:"Plan Findex Pro",
    findexproDescription:"La operativa Findex esta diseñada con parametros moderados para inversiones que estan planificando un proyecto que requiera una inversion significativa de capital a mediano plazo o para cubrir responsabilidades mensuales",
    botoncompraplan:"Comprar",
    deposit:"Depositar",
    walletAddress:"Direccion de la Wallet",
    next:"Siguiente",
    enterAmount:"Ingresa Cantidad a Depositar",
    select:"Seleccionar Plan",
    selectFindexPlan:"Selecciona tu Plan Findex",
    annual:"Anual",
    enterDepositAmount:"Cantidad a Depositar",
    alreadyHaveAccount: "Ya cuentas con una cuenta? ",
    login:"Iniciar Sesion",
    welcomeToSuccess:"Bienvenido al Exito Financiero",
    welcomeFindex:"Bienvenido a Findex",
    viewProfile:"Ver tu Perfil",
    logo:"Findex",
    referralLinkCopied:"Codigo de Referencia Copiado",
    progressChart:"Barra de Progreso",
    selectFindexPlan:"Selecciona tu Plan Findex",
    annual:"Mensual",
    usuariosReferidos:"Usuarios Referidos",
    capitalOrganizacion:"Capital de tu Organizacion",
    noInvestedProjects:"No tienes proyectos invertidos"









    }
  };
  
  export default texts;