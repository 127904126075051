import React from 'react';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';

const Support = () => {
  const { getText } = useLanguage();

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-center p-6">
      <div className="max-w-md w-full bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-green-500 mb-4">
          {getText('supportTitle')}
        </h2>
        <p className="text-gray-400 text-center mb-6">
          {getText('supportDescription')}
        </p>
        <div className="flex items-center mb-4">
          <FaEnvelope className="text-green-500 mr-4" size={24} />
          <span className="text-gray-300">support@findex.com</span>
        </div>
        <div className="flex items-center">
          <FaWhatsapp className="text-green-500 mr-4" size={24} />
          <span className="text-gray-300">+52 998 476 2836</span>
        </div>
        <p className="text-gray-400 text-center mt-6">
          {getText('supportFooter')}
        </p>
      </div>
    </div>
  );
};

export default Support;
