import React, { useState, useEffect } from 'react';
import { FaCopy, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';
import NavigationBar from './NavigationBar';

const Red = () => {
  const { getText } = useLanguage();
  const [expandedRows, setExpandedRows] = useState({});
  const [dashboardData, setDashboardData] = useState({
    referralCode: '',
    userName: '',
    level: 'N/A',
    totalInvested: 0,
    totalReferred: 0,
    referrals: []
  });
  const [organizationCapital, setOrganizationCapital] = useState(0);

  // Función para controlar la expansión de los referidos
  const toggleRow = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  // Llamada a la API para obtener los datos de la red de referidos
  useEffect(() => {
    fetchNetworkData();
  }, []);

  const fetchNetworkData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user-network-data`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();

      // Sumar el capital de los referidos
      const totalCapital = calculateTotalCapital(data.referrals);

      setDashboardData({
        level: data.level || 'N/A',
        totalInvested: data.totalInvested || 0,
        totalReferred: data.totalReferred || 0,
        referralCode: data.referralCode || '',
        userName: data.userName || '',
        referrals: data.referrals || []
      });

      // Establecer el capital total de la organización basado en los referidos
      setOrganizationCapital(totalCapital);
    } catch (error) {
      console.error('Error fetching network data:', error);
    }
  };

  // Función para calcular el capital total de los referidos
  const calculateTotalCapital = (referrals) => {
    let total = 0;

    const sumCapital = (referralsList) => {
      referralsList.forEach((referral) => {
        total += Number(referral.capitalInvested) || 0; // Ajuste: capitalInvested
        if (referral.subReferrals && referral.subReferrals.length > 0) {
          sumCapital(referral.subReferrals);
        }
      });
    };

    sumCapital(referrals);
    return total;
  };

  // Renderizar los referidos de manera anidada con sangrías
  const renderReferrals = (referrals, level = 0) => {
    return referrals.map((referral, index) => (
      <div 
        key={index} 
        style={{ paddingLeft: `${level * 20}px`, marginBottom: '8px' }} 
        className="pt-2 border border-gray-700 rounded-lg bg-gray-800 text-white p-4"
      >
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleRow(referral.username)}
        >
          <div className="text-lg font-bold">{referral.username}</div>
          <div className="flex items-center space-x-4">
            <span className="font-semibold">Nivel de referido: {referral.level}</span>  {/* Nivel del referido */}
            <span className="font-semibold">Saldo: ${referral.capitalInvested ? Number(referral.capitalInvested).toLocaleString() : '0'}</span> {/* Ajuste: capitalInvested */}
            {expandedRows[referral.username] ? (
              <FaChevronDown className="text-green-500" />
            ) : (
              <FaChevronRight className="text-green-500" />
            )}
          </div>
        </div>
        {expandedRows[referral.username] && referral.subReferrals && (
          <div>
            {renderReferrals(referral.subReferrals, level + 1)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Barra de navegación */}
      <NavigationBar userName={dashboardData.userName} />

      {/* Contenido principal */}
      <div className="p-6 space-y-8 transition-transform duration-300 ease-in-out">
        {/* Enlace de referido y botón de copiar */}
        <div className="text-center bg-gray-800 p-4 rounded-lg shadow-md">
          <div className="flex justify-center items-center space-x-2">
            <a href={`https://myfindex.net/register?ref=${dashboardData.referralCode}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
              {dashboardData.referralCode ? `https://myfindex.net/register?ref=${dashboardData.referralCode}` : getText('referralLink')}
            </a>
            <FaCopy className="text-gray-300 cursor-pointer" onClick={() => navigator.clipboard.writeText(`https://myfindex.net/register?ref=${dashboardData.referralCode}`)} />
          </div>
        </div>

        {/* Estadísticas del usuario */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <p className="text-gray-400">Nivel</p>
            <h2 className="text-2xl font-bold">{dashboardData.level}</h2>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <p className="text-gray-400">Capital Invertido</p>
            <h2 className="text-2xl font-bold">${dashboardData.totalInvested.toLocaleString()}</h2>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <p className="text-gray-400">Usuarios Referidos</p>
            <h2 className="text-2xl font-bold">{dashboardData.totalReferred}</h2>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <p className="text-gray-400">Capital Total</p>
            <h2 className="text-2xl font-bold">${organizationCapital.toLocaleString()}</h2>
          </div>
        </div>

        {/* Lista de referidos con filas expandibles */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <div className="flex justify-between items-center text-white cursor-pointer" onClick={() => toggleRow(dashboardData.userName)}>
            <div>{dashboardData.userName}</div>
            <div className="flex items-center space-x-4">
              <span>Capital Total: {organizationCapital.toLocaleString()}</span>
              {expandedRows[dashboardData.userName] ? (
                <FaChevronDown className="text-green-500" />
              ) : (
                <FaChevronRight className="text-green-500" />
              )}
            </div>
          </div>
          {expandedRows[dashboardData.userName] && renderReferrals(dashboardData.referrals)}
        </div>
      </div>
    </div>
  );
};

export default Red;
