import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Plans from './Plans';

const ProtectedRoute = ({ element: Component, isAdminRoute = false }) => {
  const [hasPlan, setHasPlan] = useState(null);  // Estado para controlar si el usuario tiene un plan
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role'); // Asegúrate de almacenar el rol cuando el usuario inicie sesión

  useEffect(() => {
    const checkUserPlan = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/check-user-plan`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok && data.hasPlan) {
          setHasPlan(true);
        } else {
          setHasPlan(false);
        }
      } catch (error) {
        console.error("Error checking user's plan:", error);
        setHasPlan(false);
      }
    };

    // Siempre llamar el hook, pero dentro aplicamos las condiciones
    if (userRole === 'user') {
      checkUserPlan();
    } else {
      setHasPlan(true); // Los administradores no necesitan un plan
    }
  }, [token, userRole]);

  // Verificar si el token ha expirado
  const isTokenExpired = (token) => {
    if (!token) return true;

    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Date.now() / 1000;
    return payload.exp < currentTime;
  };

  // Si no hay token o el token ha expirado, redirigir al login
  if (!token || isTokenExpired(token)) {
    localStorage.removeItem('token'); // Eliminar el token si está expirado
    return <Navigate to="/login" replace />;
  }

  // Si la ruta es de administrador y el usuario no es administrador, redirigir al dashboard
  if (isAdminRoute && userRole !== 'admin') {
    return <Navigate to="/dashboard" />;
  }

  // Si el usuario no tiene un plan y está intentando acceder a un componente que no es el de "Planes", redirigir a /plans
  if (userRole === 'user' && hasPlan === false && Component !== Plans) {
    return <Navigate to="/plans" />;
  }

  // Mientras se verifica si el usuario tiene un plan, mostrar un estado de carga
  if (hasPlan === null) {
    return <div>Cargando...</div>; // Puedes mostrar un spinner aquí si prefieres
  }

  // Si todo está bien, renderizar el componente solicitado
  return <Component />;
};

export default ProtectedRoute;
