import React, { useState, useEffect } from 'react';
import { FaBars, FaCopy, FaTimes } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ProgressChart from './ProgressBart';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar';

const Dashboard = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    totalInvested: 0,
    currentBalance: 0,
    referralBonus: 0,
    referralCode: '',
    userName: ''
  });

  const [chartData, setChartData] = useState({
    capitalInvested: [],
    referralBonuses: [],
    investmentEarnings: []
  });

  const { getText } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard-data`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setDashboardData({
        totalInvested: data.totalInvested || 0,
        currentBalance: data.currentBalance || 0,
        referralBonus: data.referralBonus || 0,
        referralCode: data.referralCode || '',
        userName: data.userName || '',
      });

      setChartData({
        capitalInvested: [data.totalInvested], 
        referralBonuses: [data.referralBonus],
        investmentEarnings: [1000] 
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    closeSidebar();
  };

  const copyToClipboard = () => {
    const referralLink = `https://myfindex.net/register?ref=${dashboardData.referralCode}`;
    navigator.clipboard.writeText(referralLink);
    toast.success(getText('referralLinkCopied'), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <NavigationBar userName={dashboardData.userName} />

      <div className="p-6 space-y-8">
        <div className="flex justify-between items-center bg-gray-800 p-4 rounded-lg shadow-md">
          <div className="flex justify-center items-center space-x-2">
            <a href={`https://myfindex.net/register?ref=${dashboardData.referralCode}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
              {dashboardData.referralCode ? `https:///myfindex.net/register?ref=${dashboardData.referralCode}` : getText('referralLink')}
            </a>
            <FaCopy className="text-gray-300 cursor-pointer" onClick={copyToClipboard} />
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
          <p className="text-gray-400">{getText('totalBalanceInvested')}</p>
          <h1 className="text-3xl font-bold">${dashboardData.totalInvested.toLocaleString()}</h1>
          <p className="text-gray-400 mt-2">{getText('currentBalance')}: ${dashboardData.currentBalance.toLocaleString()}</p>
          <p className="text-gray-400">{getText('referralBonus')}: ${dashboardData.referralBonus.toLocaleString()}</p>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
          <p className="text-gray-400">{getText('progressChart')}</p>
          <ProgressChart data={chartData} />
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Dashboard;
