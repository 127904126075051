import React, { useState, useEffect } from 'react';
import { FaCopy, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBar from './NavigationBar';

function DashboardPlans() {
  const [dashboardData, setDashboardData] = useState({
    referralCode: '',
    userName: '',
    plans: [],
  });
  
  const [loading, setLoading] = useState(true);
  const [withdrawals, setWithdrawals] = useState([]);
  const [earningsBreakdown, setEarningsBreakdown] = useState({
    interests: 0,
    bonuses: 0,
    referralCommissions: 0,
  });

  const { getText } = useLanguage();
  const navigate = useNavigate();
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawWallet, setWithdrawWallet] = useState('');
  const [currentWallet, setCurrentWallet] = useState('');
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawStep, setWithdrawStep] = useState(1);
  const [showCommissionMessage, setShowCommissionMessage] = useState(false); // Control para mostrar la comisión

  useEffect(() => {
    fetchDashboardPlansData();
    fetchWithdrawalsData();
    fetchEarningsBreakdownData();
    fetchWalletData();
  }, []);

  const fetchDashboardPlansData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboard-plans-data`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setDashboardData({
        referralCode: data.referralCode || '',
        userName: data.userName || '',
        plans: data.plans || [],
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard plans data:', error);
      setLoading(false);
    }
  };

  const fetchWithdrawalsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/withdrawals-history`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setWithdrawals(data || []);
    } catch (error) {
      console.error('Error fetching withdrawals data:', error);
    }
  };

  const fetchEarningsBreakdownData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/earning-breakdown`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setEarningsBreakdown({
        interests: data.interests || 0,
        bonuses: data.bonuses || 0,
        referralCommissions: data.referralCommissions || 0,
      });
    } catch (error) {
      console.error('Error fetching earnings breakdown data:', error);
    }
  };

  const fetchWalletData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user-wallet`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      setCurrentWallet(data.wallet || '');
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };

  const handleWithdrawClick = () => {
    setShowWithdrawModal(true);
    setWithdrawStep(1);
    checkCommissionCondition();
  };

  const checkCommissionCondition = () => {
    const currentDate = new Date();
    const activePlans = dashboardData.plans.filter(plan => new Date(plan.endDate) > currentDate);
    
    if (activePlans.length > 0) {
      setShowCommissionMessage(true);
    } else {
      setShowCommissionMessage(false);
    }
  };

  const handleWithdrawNext = () => {
    if (withdrawStep === 1 && withdrawAmount > 0) {
      setWithdrawStep(2);
    } else if (withdrawStep === 2) {
      if (currentWallet) {
        setWithdrawWallet(currentWallet);
      }
      setWithdrawStep(3);
    } else if (withdrawStep === 3) {
      processWithdraw();
    }
  };

  const processWithdraw = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/withdraw`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ amount: withdrawAmount, wallet: withdrawWallet }),
      });

      if (response.ok) {
        toast.success("Su retiro será procesado en un tiempo máximo de 72 horas a partir de este momento", {
          position: "top-center",
          autoClose: 5000,
        });
        setShowWithdrawModal(false);
      } else {
        const responseData = await response.json();
        toast.error(`Error: ${responseData.message}`, {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error("Hubo un error procesando su retiro.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const copyToClipboard = () => {
    const referralLink = `https://myfindex.net/register?ref=${dashboardData.referralCode}`;
    navigator.clipboard.writeText(referralLink);
    toast.success(getText('referralLinkCopied'), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const navigateToPlans = () => {
    navigate('/plans');
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <NavigationBar userName={dashboardData.userName} />

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <p className="text-white text-xl">Cargando datos, por favor espera...</p>
        </div>
      ) : (
        <div className="p-6 space-y-8 transition-transform duration-300 ease-in-out">
          <div className="text-center bg-gray-800 p-4 rounded-lg shadow-md">
            <div className="flex justify-center items-center space-x-2">
              <a href={`https://myfindex.net/register?ref=${dashboardData.referralCode}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                {dashboardData.referralCode ? `https://myfindex.net/register?ref=${dashboardData.referralCode}` : getText('referralLink')}
              </a>
              <FaCopy className="text-gray-300 cursor-pointer" onClick={copyToClipboard} />
            </div>
          </div>

          <div className="flex justify-end">
            <button 
              onClick={navigateToPlans} 
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-500"
            >
              Comprar Planes
            </button>
          </div>

          {/* Tabla de Planes */}
          <div className="mb-6 overflow-x-auto">
            <h2 className="text-2xl font-bold text-teal-400 mb-4">Mis Planes Findex</h2>
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="border-b border-gray-700 p-4">Plan</th>
                  <th className="border-b border-gray-700 p-4">Invertido</th>
                  <th className="border-b border-gray-700 p-4">Porcentaje Generado</th> {/* Nueva Columna */}
                  <th className="border-b border-gray-700 p-4">Fecha de Adquisición</th> {/* Nueva Columna */}
                  <th className="border-b border-gray-700 p-4">Total Actual</th>
                  <th className="border-b border-gray-700 p-4">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {dashboardData.plans.map((plan, index) => (
                  <tr key={index} className="hover:bg-gray-700">
                    <td className="border-b border-gray-700 p-4">{plan.name}</td>
                    <td className="border-b border-gray-700 p-4">${plan.capitalInvested ? plan.capitalInvested.toLocaleString() : 'N/A'}</td>
                    <td className="border-b border-gray-700 p-4">{plan.percentageGenerated ? `${plan.percentageGenerated}%` : 'N/A'}</td> {/* Mostrar porcentaje generado */}
                    <td className="border-b border-gray-700 p-4">{plan.purchaseDate ? new Date(plan.purchaseDate).toLocaleDateString() : 'N/A'}</td> {/* Mostrar fecha de adquisición */}
                    <td className="border-b border-gray-700 p-4">${plan.currentBalance ? plan.currentBalance.toLocaleString() : 'N/A'}</td>
                    <td className="border-b border-gray-700 p-4">
                      <button 
                        onClick={handleWithdrawClick} 
                        className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-500"
                      >
                        Retirar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Tabla de Historial de Retiros */}
          <div>
            <h2 className="text-2xl font-bold text-teal-400 mb-4">Historial de Retiros</h2>
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="border-b border-gray-700 p-4">Fecha</th>
                  <th className="border-b border-gray-700 p-4">Monto</th>
                  <th className="border-b border-gray-700 p-4">Estado</th>
                </tr>
              </thead>
              <tbody>
                {withdrawals.length > 0 ? (
                  withdrawals.map((withdrawal, index) => (
                    <tr key={index} className="hover:bg-gray-700">
                      <td className="border-b border-gray-700 p-4">{new Date(withdrawal.date).toLocaleDateString()}</td>
                      <td className="border-b border-gray-700 p-4">${withdrawal.amount.toLocaleString()}</td>
                      <td className="border-b border-gray-700 p-4">{withdrawal.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center p-4 text-gray-400">No hay retiros realizados.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Withdraw Modal - Paso 1: Ingresar cantidad */}
      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showWithdrawModal && withdrawStep === 1 ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-end">
            <FaTimes className="text-white cursor-pointer" onClick={() => setShowWithdrawModal(false)} />
          </div>
          <h2 className="text-xl font-bold text-white mb-4">Ingrese la cantidad a retirar</h2>
          <input 
            type="number" 
            className="w-full p-2 rounded-md bg-gray-700 text-white border-none"
            value={withdrawAmount} 
            onChange={(e) => setWithdrawAmount(e.target.value)} 
            placeholder="Ingrese la cantidad"
          />
          <button 
            className="mt-4 w-full px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
            onClick={handleWithdrawNext}
          >
            Siguiente
          </button>
        </div>
      </div>

      {/* Withdraw Modal - Paso 2: Confirmar o ingresar wallet */}
      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showWithdrawModal && withdrawStep === 2 ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-end">
            <FaTimes className="text-white cursor-pointer" onClick={() => setShowWithdrawModal(false)} />
          </div>
          <h2 className="text-xl font-bold text-white mb-4">Confirmar Wallet para Retiro</h2>
          {currentWallet ? (
            <p className="text-white">Su wallet actual: <span className="text-green-400">{currentWallet}</span></p>
          ) : (
            <input 
              type="text" 
              className="w-full p-2 rounded-md bg-gray-700 text-white border-none"
              value={withdrawWallet} 
              onChange={(e) => setWithdrawWallet(e.target.value)} 
              placeholder="Ingrese su wallet"
            />
          )}
          <button 
            className="mt-4 w-full px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
            onClick={handleWithdrawNext}
          >
            Siguiente
          </button>
        </div>
      </div>

      {/* Withdraw Modal - Paso 3: Confirmación final */}
      <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 ${showWithdrawModal && withdrawStep === 3 ? '' : 'hidden'}`}>
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex justify-end">
            <FaTimes className="text-white cursor-pointer" onClick={() => setShowWithdrawModal(false)} />
          </div>
          <h2 className="text-xl font-bold text-white mb-4">Confirmación de Retiro</h2>
          <p className="text-white mb-4">
            Va a retirar <span className="text-green-400">${withdrawAmount}</span> a la wallet: <span className="text-green-400">{withdrawWallet || currentWallet}</span>.
          </p>
          {showCommissionMessage && (
            <p className="text-red-400">
              Nota: Al hacer el retiro se te cobrará un 30 % de comisión sobre la cantidad a retirar, ya que el plan aún no ha vencido.
            </p>
          )}
          <button 
            className="mt-4 w-full px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
            onClick={handleWithdrawNext}
          >
            Confirmar
          </button>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default DashboardPlans;
