import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Plans from './components/Plans';
import CurrencyTicker from './components/CurrencyTicker';
import { LanguageProvider } from './contexts/LanguageContext';
import './App.css';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import DashboardPlans from './components/DashboardPlans';
import Operativa from './components/Operativa';
import Crowdfunding from './components/Crowdfunding';
import Red from './components/Red';
import ProtectedRoute from './components/ProtectedRoute';
import UserProfile from './components/UserProfile';
import Support from './components/Support';
import AdminPortal from './components/AdminPortal';
import AdminUsers from './components/AdminUsers';
import AdminPlans from './components/AdminPlans';
import AdminSettings from './components/AdminSettings';
import AdminWithdrawals from './components/AdminWithdrawals';  // Importar el nuevo componente
import ProcessPay from './components/ProcessPay';  // También el componente de pagos si aún no está
import Commissions from './components/Comissions';
import TermsAndConditions from './components/TermsAndConditions';  // También el componente de pagos si aún no está


function App() {
  return (
    <LanguageProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Rutas públicas */}
            <Route path="/" element={<><Home /><Footer /></>} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />


            {/* Rutas protegidas para usuarios logueados */}
            <Route path="/plans" element={<ProtectedRoute element={Plans} />} />
            <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
            <Route path="/dashboardplans" element={<ProtectedRoute element={DashboardPlans} />} />
            <Route path="/operativa" element={<ProtectedRoute element={Operativa} />} />
            <Route path="/crowdfunding" element={<ProtectedRoute element={Crowdfunding} />} />
            <Route path="/red" element={<ProtectedRoute element={Red} />} />
            <Route path="/profile" element={<ProtectedRoute element={UserProfile} />} />
            <Route path="/support" element={<ProtectedRoute element={Support} />} />


            

            {/* Ruta para la sala de espera de verificación del pago */}
            <Route path="/processpay" element={<ProtectedRoute element={ProcessPay} />} />

            {/* Rutas protegidas solo para administradores */}
            <Route path="/admin" element={<ProtectedRoute element={AdminPortal} isAdminRoute={true} />} />
            <Route path="/admin/users" element={<ProtectedRoute element={AdminUsers} isAdminRoute={true} />} />
            <Route path="/admin/plans" element={<ProtectedRoute element={AdminPlans} isAdminRoute={true} />} />
            <Route path="/admin/settings" element={<ProtectedRoute element={AdminSettings} isAdminRoute={true} />} />
            <Route path="/admin/withdrawals" element={<ProtectedRoute element={AdminWithdrawals} isAdminRoute={true} />} /> {/* Nueva ruta para administrar retiros */}
            <Route path="/admin/commissions" element={<ProtectedRoute element={Commissions} isAdminRoute={true} />} />

          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;
